.coverImage {
  aspect-ratio: 16/9;
  object-fit: cover;
}

.actionsContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: var(--size-spacing-03);
  flex-direction: column;
}

.actionButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-neutral-500);
  border-radius: 7px;
  padding: var(--size-spacing-03);
  box-shadow:
    0 5px 10px rgb(154 160 185 / 5%),
    0 15px 40px rgb(166 173 201 / 20%);
  color: var(--color-text-dark);
  cursor: pointer;
  display: block;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: var(--color-neutral-500);
  }

  svg {
    fill: var(--color-text-dark);
  }
}
