.content {
  position: relative;
  overflow: hidden;
  margin-bottom: var(--size-spacing-04);
}

.gradient {
  position: absolute;
  width: 100%;
  height: 18px;
  bottom: 0px;
  background: linear-gradient(180deg, #FEFEFF 14.58%, rgba(254, 254, 255, 0) 100%);
  transform: rotate(-180deg);
}
